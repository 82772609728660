<template>
  <div class="wrapper flex-div">
    <div class="wrapper-body flex-div">
    <header class="web-header" :style="{backgroundColor:manifest.theme_color}">
      <HeaderBar />
    </header>
    <main class="content-wrapper flex-div">
  
        <router-view />
     
      
    </main>
  </div>
</div>



</template>
<script>

import HeaderBar from "../components/web/HeaderBar.vue"
export default {
  components: {
    HeaderBar
  },
  mounted() {
    // this is dashboard layout
  }
}
</script>
<style>
.wrapper {
    background-color: #eee;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
    padding: 20px;

}
.wrapper-body{
  border-radius: 20px;
  background-color: #fff;
}

.web-header {
    background-color: #4e73df;
    height: 300px;

}
.content-wrapper{

    margin-top: -200px;
    margin-bottom: 25px;
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.tab-content {
    overflow-y: auto;

}

</style>