<template>
  <div class="container">
    <div class="header-section">
      <nav
        class="navbar navbar-expand-lg navbar-light px-0"
        :style="{ backgroundColor: manifest.theme_color }"
      >
        <a class="navbar-brand d-flex align-items-center m-0" href="/">
          <img :src="manifest.icons[3].src" width="70" />
          <div class="logo-text color-white mr-1 d-flex align-items-center flex-column">
            <span>سامانه اندیشه‌کاوی</span>
            <span> {{ manifest.name }}</span>
          </div>
        </a>
        <button v-if="HasAccountBox"
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="far fa-user-circle text-white font-weight-normal"></span>
        </button>

        <div class="collapse navbar-collapse shadow-lg p-3 p-md-0 bg-md rounded" id="navbarSupportedContent" v-if="HasAccountBox">
          <ul class="navbar-nav mr-auto" >
            <li class="nav-item dropdown  ">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  class="img-profile rounded-circle"
                  src="/images/avator.jpg"
                  width="30"
                />
                {{ userName }}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link
                  class="dropdown-item"
                  to="dashboard"
                  v-if="Access('SurvayManager,SysAdmin,SurvayResultManager,UserManager')"
                  >ورود به پنل</router-link
                >
                <div class="dropdown-divider"></div>
                <button class="dropdown-item" to="dashboard" @click="SignOut()">
                  خروج
                </button>
              </div>
            </li>
            <li class="nav-item">
              <router-link to="Wallet" class="nav-link" role="button">
                <img
                  class="img-profile rounded-circle"
                  src="/images/starcoin.png"
                  width="30"
                />
                موجودی شما : {{ WalletBalance }}
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import { Loading } from "../../public/js/Utilities"
import { RoleAccess, Loading } from "../../../public/js/Utilities";

export default {
  data() {
    return {
      mobile: window.innerWidth <= 767,
      userName: "",
      WalletBalance: 0,
      HasAccountBox: true,
    };
  },
  watch: {
    $route(to) {
      if (to.path == "/") {
        this.HasAccountBox = true;
      } else {
        this.HasAccountBox = false;
      }
    },
  },
  mounted() {
    if (this.$route.path == "/") {
      this.HasAccountBox = true;
    } else {
      this.HasAccountBox = false;
    }
    this.userName = localStorage.getItem("userName");
    if (this.mobile == true) {
      document.getElementById("page-top").classList.add("sidebar-toggled");
      if (document.getElementById("accordionSidebar")) {
        document.getElementById("accordionSidebar").classList.add("toggled");
      }
    }

    if (this.HasAccountBox) {
      // this.LoadSurvay();
      this.LoadWalletBalance();
    }
  },
  methods: {
    SignOut() {
      localStorage.clear();
      window.location.href = "/";
    },
    Access(RoleNames) {
      return RoleAccess(RoleNames);
    },
    // LoadSurvay() {
    //     Loading(1);
    //     axios.get("/Survay/GetByCurrentParticipant").then((res) => {
    //         if (res.data.value != null) {
    //             this.SurvayList = res.data.value;
    //         }
    //         Loading(0);
    //     });
    // },
    GoSurvay() {
      this.LoadSurvay();
    },

    LoadWalletBalance() {
      Loading(1);
      axios.get("/Wallet/WalletBalanceCurrentUser").then((res) => {
        if (res.data.value != null) {
          this.WalletBalance = res.data.value;
        }
        Loading(0);
      });
    },
  },
};
</script>
<style>
.header-section .navbar .navbar-brand {
  border-radius: 100%;
}

.header-section .navbar .navbar-brand img {
  border-radius: 100%;
}

.header-section .navbar .navbar-brand .logo-text {
  color: #fff;
  font-size: 14px;
}

.header-section .navbar .navbar-nav {
  background-color: #fff;
  border-radius: 0.35rem;
}
@media only screen and (max-width: 768px) {
  .navbar-collapse {
    background-color: #fff;
  }
}


</style>
